<template>
  <div>
    <div
      class="former-section white--text mt-4"
      v-for="(trainer, idx) of listTrainer"
      :key="`trainer-mobile-${idx}`"
    >
      <div class="former-section__wrap">
        <p class="former-section__wrap__title">
          {{ $t('admin.training.trainingList.table.headers.trainer') }}
        </p>
        <div class="former-section__wrap__content">
          <div style="width: 50%" class="former-section__wrap__content__avatar d-flex align-center">
            <v-avatar size="60">
              <img v-if="trainer.avatar" :src="trainer.avatar" alt="avatar" />
            </v-avatar>
            <div class="former-section__wrap__content__section__avatar__info ml-2">
              <div class="info__name font-weight-bold">
                {{ `${trainer.firstName} ${trainer.lastName}` }}
              </div>
            </div>
          </div>
          <div class="former-section__wrap__content__action align-center" style="opacity: 0">
            <div class="former-section__wrap__content__action__top-row">
              <div
                class="former-section__wrap__content__action__top-row__message mr-2 d-flex align-center"
              >
                <v-icon size="13" class="mr-1">mdi-message-text-outline</v-icon>
                Message
              </div>
              <div
                class="former-section__wrap__content__action__top-row__video-call d-flex align-center"
              >
                <v-icon size="13" class="mr-1">mdi-message-video</v-icon>
                Video Call
              </div>
            </div>
            <div
              class="former-section__wrap__content__action__top-row__schedule justify-center mt-2 d-flex align-center"
            >
              <v-icon size="13" class="mr-1">mdi-calendar-month-outline</v-icon>
              Schedule
            </div>
          </div>
        </div>
      </div>
      <div class="former-section__description">
        <p class="former-section__description__title">Description</p>
        <div class="former-section__description__content">
          <div class="mb-0 mt-4 black--text ql-editor" v-html="trainer.about"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  CLEAR_ALL_TRAINING,
  DELETE_TRAINING,
  GET_ALL_TRAININGS_ADMIN,
  GET_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';

export default {
  name: 'NotesSection',
  components: {},
  props: {
    showDownloadBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    heightDrag: {
      type: String,
      required: false,
      default: '400px',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'white',
    },
    showNoteImage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    listTrainer: [],
  }),
  computed: {
    ...mapGetters(TRAINING_ADMIN_MODULE, [
      'allTrainingState',
      'trainingStateAreLoading',
      'allTrainingData',
      'trainingData',
    ]),
  },
  methods: {
    ...mapActions(TRAINING_ADMIN_MODULE, [
      GET_ALL_TRAININGS_ADMIN,
      DELETE_TRAINING,
      CLEAR_ALL_TRAINING,
      GET_TRAINING,
    ]),
  },
  async mounted() {
    await this[GET_TRAINING]({
      eventId: this.$route.params.eventId,
      trainingId: this.$route.params.trainingId,
    });
    this.listTrainer = this.trainingData?.trainers ?? [];
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/core/variables';

.former-section {
  &__wrap {
    &__title {
      display: none;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__action {
        .v-icon {
          color: var(--v-primary-base);
        }

        &__top-row {
          display: flex;

          &__message,
          &__schedule,
          &__video-call {
            color: black;
            background: white;
            font-size: $small-font-size;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            border-radius: 4px;
          }
        }
      }
    }
  }

  &__description {
    &__title {
      display: none;
    }

    &__content {
      margin-top: 1rem;
    }
  }

  & .ql-editor {
    ::v-deep img {
      max-width: 100%;
    }
  }

  @include breakpoint(medium) {
    &__wrap {
      &__title {
        display: unset;
      }

      &__content {
        padding-left: 56px;
        padding-right: 56px;
        display: flex;

        &__action {
          &__top-row {
            display: flex;

            &__message,
            &__schedule,
            &__video-call {
              font-weight: bold;
              color: white;
              background: transparent;
              padding-left: 1rem;
              padding-right: 1rem;
            }
          }
        }
      }
    }
    &__description {
      &__title {
        display: block;
      }

      &__content {
        margin-top: 0rem;
      }
    }
  }
}
</style>
