export enum rightItemType {
  resources = 'files',
  note = 'note',
  reading = 'reading',
  video = 'video',
  quizz = 'quizz',
  link = 'websiteLink',
  deposit = 'deposit',
  process = 'process',
}
