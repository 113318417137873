<template>
  <div v-if="item" class="trainings-lesson-item">
    <!--Video -->
    <div v-if="item.type === trainingLessonTypeModel.VIDEO" class="trainings-lesson-item--video">
      <div class="trainings-lesson-item--video__wrap-img">
        <span class="white--text font-weight-bold text__title">Video</span>
        <div v-if="!isPlay" style="position: relative">
          <v-img :src="item.content.video.thumbnailImage">
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
              </div>
            </template>
          </v-img>
          <v-icon
            color="gray"
            size="80"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;
            "
            @click="isPlay = true"
          >
            mdi-play-circle-outline
          </v-icon>
        </div>
        <vimeo-video
          v-else
          :autoplay="!!item.content.video.thumbnailImage"
          :video-url="item.content.video.content"
          class="video"
          @end="onSubmitLesson({ ...item, isDone: true })"
        />
      </div>
    </div>
    <!--Reading -->
    <div
      v-else-if="item.type === trainingLessonTypeModel.READING"
      class="trainings-lesson-item--reading"
    >
      <span class="white--text font-weight-bold trainings-lession-item--reading__title text__title"
        >Reading</span
      >
      <div class="trainings-lesson-item--reading--content ql-container">
        <div
          class="trainings-lesson-item--reading--content--body black--text ql-editor"
          data-gramm="false"
          spellcheck="false"
          v-html="item.content.reading.content"
        ></div>
        <div class="trainings-lesson-item--reading--content--footer mx-auto">
          <button
            class="d-flex justify-xl-space-around justify-center align-center pr-0"
            @click="onSubmitLesson({ ...item, isDone: true })"
          >
            <span class="ml-6 white--text">Mark as read</span>
            <v-icon color="white" size="35"> mdi-check</v-icon>
          </button>
        </div>
      </div>
    </div>
    <!--Quiz-->
    <div v-else-if="item.type === trainingLessonTypeModel.QUIZ && !isLoading">
      <TrainingsSectionItemQuiz
        :key="`${item.id}-${item.isDone ? 'true' : 'false'}`"
        :data="item.content.quizz.questions"
        :isLoading="isLoading"
        :item.sync="item"
        :layout="item.content.quizz.layout"
        :navigationAllowed="navigationAllowed"
        @update-navigationAllowed="updateNavigationAllowed"
        @submit="onSubmitLesson"
        @set-isInProgressCurrentLesson="setIsInProgressCurrentLesson"
      ></TrainingsSectionItemQuiz>
    </div>
    <!-- PDF -->
    <div
      v-else-if="item.type === trainingLessonTypeModel.PDF"
      class="trainings-lesson-item--reading"
    >
      <span class="white--text font-weight-bold trainings-lession-item--reading__title text__title"
        >Reading</span
      >
      <div class="trainings-lesson-item--reading--content--header mx-auto">
        <button
          class="d-flex justify-xl-space-around justify-center align-center pr-0"
          @click="onSubmitLesson({ ...item, isDone: true })"
        >
          <span class="ml-6 white--text">Mark as read</span>
          <v-icon color="white" size="35"> mdi-check</v-icon>
        </button>
      </div>

      <vue-pdf-app
        :pdf="item.content.pdf.content"
        :config="pdfConfig"
        style="height: 100vh"
      ></vue-pdf-app>
    </div>
    <!-- FILE-->
    <div v-else :key="`lession-id-key-${item.id}`" class="trainings-lesson-item">
      <TrainingsSectionItemDefault :item="item" :trainingData="trainingData" />
    </div>
  </div>
  <!--Default-->
  <div v-else class="trainings-lesson-item">
    <div class="trainings-lesson-item--img mt-4">
      <img v-if="trainingData" :src="trainingData.coverImage" />
    </div>
  </div>
</template>
<script>
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';

import { trainingLessonTypeModel } from '@/models/training/training-lesson-type.model';
import {
  COMPLETE_LESSION_TRAINING_SECTION,
  TRAINING_SECTION_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';
import { mapActions, mapGetters } from 'vuex';
import VimeoVideo from '@/components/video/Video.vue';
import {
  GET_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import TrainingsSectionItemQuiz from './trainings-lesson-item-quiz/TrainingsSectionItemQuiz.vue';
import TrainingsSectionItemDefault from './trainings-lesson-item-default/TrainingsSectionItemDefault.vue';

const getSidebar = () => ({
  viewThumbnail: true,
  viewOutline: true,
  viewAttachments: true,
});
const getSecondaryToolbar = () => ({
  secondaryPresentationMode: true,
  secondaryOpenFile: false,
  secondaryPrint: true,
  secondaryDownload: true,
  secondaryViewBookmark: false,
  firstPage: true,
  lastPage: true,
  pageRotateCw: false,
  pageRotateCcw: false,
  cursorSelectTool: false,
  cursorHandTool: false,
  scrollVertical: true,
  scrollHorizontal: true,
  scrollWrapped: true,
  spreadNone: true,
  spreadOdd: true,
  spreadEven: true,
  documentProperties: false,
});
const getToolbarViewerLeft = () => ({
  findbar: true,
  previous: true,
  next: true,
  pageNumber: true,
});
const getToolbarViewerRight = () => ({
  presentationMode: true,
  openFile: false,
  print: false,
  download: true,
  viewBookmark: false,
});
const getToolbarViewerMiddle = () => ({
  zoomOut: true,
  zoomIn: true,
  scaleSelectContainer: true,
});
const getToolbar = () => ({
  toolbarViewerLeft: getToolbarViewerLeft(),
  toolbarViewerRight: getToolbarViewerRight(),
  toolbarViewerMiddle: getToolbarViewerMiddle(),
});

export default {
  name: 'TrainingsLessonItem',
  components: {
    TrainingsSectionItemQuiz,
    VimeoVideo,
    TrainingsSectionItemDefault,
    VuePdfApp,
  },
  props: {
    item: {
      type: Object,
    },
    navigationAllowed: {
      type: Boolean,
    },
    trainingsSections: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      trainingLessonTypeModel,
      isPlay: false,
      isLoading: false,
      pdfConfig: {
        sidebar: getSidebar(),
        secondaryToolbar: getSecondaryToolbar(),
        toolbar: getToolbar(),
        errorWrapper: false,
      },
    };
  },
  computed: {
    ...mapGetters(TRAINING_ADMIN_MODULE, ['trainingData']),
  },
  methods: {
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [COMPLETE_LESSION_TRAINING_SECTION]),
    ...mapActions(TRAINING_ADMIN_MODULE, [GET_TRAINING]),
    updateNavigationAllowed(allowed) {
      this.$emit('update-navigationAllowed', allowed);
    },
    setIsInProgressCurrentLesson(boolean) {
      this.$emit('set-isInProgressCurrentLesson', boolean);
    },
    async onSubmitLesson({ sectionId, trainingId, id, score, nbAttempts, isDone }) {
      this.isLoading = true;

      await this[COMPLETE_LESSION_TRAINING_SECTION]({
        eventId: this.$route.params.eventId,
        sectionId,
        trainingId,
        lessonId: id,
        score,
        nbAttempts,
        isDone,
      });

      if (isDone) {
        // Go to next lesson
        const lessonList = [];
        this.trainingsSections.forEach((ele) => {
          lessonList.push(...ele.lessons);
        });
        const curIndex =
          lessonList.findIndex(
            (ele) =>
              ele.id === this.$route.params.lessonId &&
              ele.sectionId === this.$route.params.sectionId,
          ) ?? 0;

        if (curIndex === -1) return;
        await this.$router.replace({
          query: {
            type: lessonList[curIndex + 1]?.type,
          },
          params: {
            sectionId: lessonList[curIndex + 1]?.sectionId,
            lessonId: lessonList[curIndex + 1]?.id,
          },
        });
      }
      this.isLoading = false;
    },
  },
  mounted() {
    if (this.item?.type === trainingLessonTypeModel.VIDEO) {
      if (!this.item.content.video.thumbnailImage) {
        this.isPlay = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/core/variables';

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::v-deep .ql-editor {
  p {
    margin-bottom: 0px;
  }

  a {
    color: #06c;
  }

  pre {
    white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 3px;
  }

  img {
    max-width: 100%;
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }

  pre.ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow-x: visible;
  }
}

.trainings-lesson-item {
  width: 100%;
  z-index: 2;

  .text {
    &__title {
      font-size: $xx-large-font-size;
      margin-bottom: 1rem;
      display: none;
    }
  }

  &--img {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      max-width: 1024px;
      max-height: 500px;
    }
  }

  &--video {
    &__wrap-img {
      justify-content: start;

      img {
        width: 100%;
        max-height: 550px;
      }
    }
  }

  &--reading {
    &--content {
      border-radius: 5px;
      height: 550px;
      padding: 15px 60px;
      background: white;

      &--body {
        height: 465px;
        overflow: scroll;

        ::v-deep img {
          max-width: 100%;
        }
      }

      &--footer {
        display: flex;
        justify-content: right;

        button {
          width: 100%;
          height: 50px;
          background: #00b5a7 0% 0% no-repeat padding-box;
          border-radius: 5px;
          position: relative;
          font-weight: bold;
          padding-right: 30px;
          margin-top: 10px;
          margin-bottom: 10px;

          img {
            position: absolute;
            right: 1rem;
            transform: translateY(3px);
          }
        }
      }

      &--header {
        display: flex;
        justify-content: right;
        margin-right: 0 !important;

        button {
          width: 100%;
          height: 50px;
          background: #00b5a7 0% 0% no-repeat padding-box;
          border-radius: 5px;
          position: relative;
          font-weight: bold;
          padding-right: 30px;
          margin-top: 10px;
          margin-bottom: 10px;

          img {
            position: absolute;
            right: 1rem;
            transform: translateY(3px);
          }
        }
      }
    }
  }

  .video {
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
  }

  @include breakpoint(medium) {
    width: calc(100% - 80px);
    margin: auto;
    .text {
      &__title {
        display: block;
      }
    }
    &--reading {
      &--content {
        &--footer {
          height: 40px;
          width: 14rem;
        }
        &--header {
          height: 70px;
          width: 14rem;
        }
      }
    }
  }
}
</style>
