<template>
  <v-dialog content-class="v-dialog--overlay" v-model="open" width="500">
    <v-card>
      <v-card-title
        class="d-flex justify-center popup-header pa-0 box-shadow-default"
        style="box-shadow: 3px 3px 10px #00000029; position: relative"
      >
        <v-icon size="32"> mdi-folder</v-icon>
        <v-icon @click="close" color="primary" class="close-btn">mdi-close</v-icon>
      </v-card-title>

      <v-card-text class="grey lighten-2 px-2" style="padding-bottom: 0.2px; padding-top: 0.2px">
        <template v-for="(item, idx) in listFile">
          <div
            class="d-flex mb-1 mt-2 px-2 docs-item justify-space-between"
            :key="`file-item-${idx}`"
          >
            <span class="text-truncate">
              <v-menu open-on-hover top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <span text v-bind="attrs" v-on="on" class="text-truncate mt-1 black--text">
                    {{ item.content }}
                  </span>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title>{{ item.content }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
            <a :href="item.file" target="_blank" style="text-decoration: none">
              <v-icon size="28" class="ml-auto" color="black"> mdi-download</v-icon>
            </a>
          </div>
          <v-divider :key="idx" v-if="idx !== listFile.length - 1" />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DownloadDocuments',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    listFile: {
      type: Array,
      default: () => [],
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    docs: [
      {
        name: 'Document 1.pdf',
      },
      {
        name: 'Document 2.xlsx',
      },
      {
        name: 'Document 1.doc',
      },
    ],
  }),
  computed: {
    open: {
      get() {
        return this.dialog;
      },
      set() {
        return this.close();
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

.popup-header {
  height: 64px;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
}

.docs-item {
  font-size: $default-font-size;
  font-weight: bold;
}

.box-shadow-default {
  box-shadow: 0 0 8px var(--v-gray-base);
}
</style>
