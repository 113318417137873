<template>
  <v-card
    color="trainings-note d-flex flex-column"
    v-bind:style="{ backgroundColor: backgroundColor }"
  >
    <div class="d-flex setting" style="z-index: 999; height: 100%">
      <v-btn icon class="ml-auto" @click="isEditing = true" v-if="!isEditing">
        <v-icon> mdi-cog-outline </v-icon>
      </v-btn>
      <v-btn v-else class="ml-auto" depressed color="primary" @click="updateNote">
        {{ $t('globals.save') }}
      </v-btn>
    </div>

    <Draggable
      v-model="listNote"
      class="pb-2 content-note"
      v-bind:style="{ height: heightDrag }"
      :disabled="!isEditing"
    >
      <div
        v-for="info in listNote"
        :key="info.id"
        class="mt-8 d-flex"
        :class="{ draggable: isEditing }"
      >
        <v-icon class="mr-4" color="error" v-if="isEditing" @click="deleteNote(info.id)">
          mdi-delete-outline
        </v-icon>
        <div class="d-flex align-center" style="width: 100%" @click="isEditing && editNote(info)">
          <div v-if="!isEditing || !info.isEdit">{{ info.note }}</div>
          <v-textarea
            v-else
            v-model="info.note"
            :placeholder="$t('trainings.addNote')"
            rows="1"
            cols="12"
            auto-grow
            class=""
            full-width
          ></v-textarea>
        </div>
        <v-icon class="ml-auto" v-if="isEditing"> mdi-menu </v-icon>
      </div>
    </Draggable>

    <v-textarea
      v-model="currentNote"
      :placeholder="$t('trainings.addNote')"
      class="flex-shrink-1 flex-grow-0"
      @keypress.enter="addNote"
      rows="1"
      auto-grow
    ></v-textarea>
  </v-card>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';

import {
  ADD_TRAINING_NOTE,
  DELETE_TRAINING_NOTE,
  GET_ALL_TRAINING_NOTES,
  TRAINING_NOTE_MODULE,
  UPDATE_LIST_TRAINING_NOTE,
} from '@/stores/umanize-admin/actions/training-note/trainings-note.actions';

export default {
  name: 'TrainingsNote',
  components: {
    Draggable,
  },
  props: {
    heightDrag: {
      type: String,
      default: '400px',
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    showNoteImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentNote: '',
      isEditing: false,
      listNote: [],
      dragItems: [
        {
          img: 'https://picsum.photos/id/200/300',
          text: '1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, tenetur.',
          id: 1,
        },
        {
          img: 'https://picsum.photos/id/237/200/300',
          text: '2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, tenetur.',
          id: 2,
        },
        {
          img: 'https://picsum.photos/id/236/200/300',
          text: '3 Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, tenetur.',
          id: 3,
        },
      ],
    };
  },
  watch: {
    allNoteState(newValue) {
      this.listNote = newValue.note;
    },
    listNote(newValue) {
      this.$emit('on-note-change', newValue);
    },
  },
  computed: {
    ...mapGetters(TRAINING_NOTE_MODULE, ['allNoteState']),
  },
  methods: {
    ...mapActions(TRAINING_NOTE_MODULE, [
      GET_ALL_TRAINING_NOTES,
      ADD_TRAINING_NOTE,
      DELETE_TRAINING_NOTE,
      UPDATE_LIST_TRAINING_NOTE,
    ]),
    async addNote() {
      const { eventId, trainingId } = this.$route.params;
      const note = { trainingId, note: this.currentNote };
      await this[ADD_TRAINING_NOTE]({ eventId, trainingId, note });
      this.currentNote = '';
      await this[GET_ALL_TRAINING_NOTES]({ eventId, trainingId });
    },
    async deleteNote(id) {
      const { eventId, trainingId } = this.$route.params;
      const note = this.allNoteState.note.find((e) => e.id === id);
      await this[DELETE_TRAINING_NOTE]({ eventId, trainingId, note });
      await this[GET_ALL_TRAINING_NOTES]({ eventId, trainingId });
    },
    async editNote(item) {
      const newList = [...this.listNote];
      newList.forEach((ele) => {
        if (ele.id === item.id) {
          // eslint-disable-next-line no-param-reassign
          ele.isEdit = true;
        }
      });
      this.listNote = newList;
    },
    async updateNote() {
      const { eventId, trainingId } = this.$route.params;
      const listUpdateNote = this.listNote.filter(
        (e, index) => e.note !== this.allNoteState.note[index].note,
      );
      this.listNote.forEach((e) => {
        if (e.isEdit) {
          e.isEdit = false;
        }
      });
      await this[UPDATE_LIST_TRAINING_NOTE]({ eventId, trainingId, listUpdateNote });
      this.isEditing = false;
    },
  },
  async mounted() {
    const { eventId, trainingId } = this.$route.params;
    await this[GET_ALL_TRAINING_NOTES]({ eventId, trainingId });
    this.listNote = [];
    this.allNoteState.note.forEach((ele) => {
      this.listNote.push({ ...ele });
    });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/core/variables';

.trainings-note {
  padding-block: 18px;
  padding-inline: 24px;

  .content-note {
    overflow-y: scroll;
    overflow-wrap: anywhere;
    overflow-x: hidden;
  }
}

.setting {
  height: 24px;
}

.draggable {
  cursor: pointer;
}
</style>
