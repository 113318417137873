<template>
  <div class="d-flex flex-column align-center mb-4">
    <TrainingsNote
      class="note-card"
      :heightDrag="heightDrag"
      :showNoteImage="showNoteImage"
      :backgroundColor="backgroundColor"
      @on-note-change="onNoteChange"
    />
    <v-btn
      v-if="showDownloadBtn"
      large
      depressed
      color="primary"
      class="mt-5 download-btn hidden-sm-and-down"
      @click="downloadNote"
    >
      {{ $t('trainings.downloadNotes') }}
      <v-icon right dark size="20"> mdi-download </v-icon>
    </v-btn>
  </div>
</template>

<script>
import TrainingsNote from '@/components/trainings/trainings-note/TrainingsNote.vue';
import { mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import { TRAINING_ADMIN_MODULE } from '@/stores/umanize-admin/actions/trainings/trainings.actions';

export default {
  name: 'NotesSection',
  components: {
    TrainingsNote,
  },
  data: () => ({
    listNoteDownload: [],
  }),
  props: {
    showDownloadBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    heightDrag: {
      type: String,
      required: false,
      default: '400px',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'white',
    },
    showNoteImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(TRAINING_ADMIN_MODULE, ['trainingData']),
  },
  methods: {
    downloadNote() {
      const noteValue = new Blob(
        this.listNoteDownload.map((e) => `${e.note}\n\n\n`),
        { type: 'text/plain;charset=utf-8' },
      );
      saveAs(noteValue, `${this.trainingData?.name}-note.txt`);
    },
    onNoteChange(noteList) {
      this.listNoteDownload = noteList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/core/variables';

.note-card {
  width: 100%;
}

.download-btn {
  width: 60%;
  max-width: 240px;
}
</style>
