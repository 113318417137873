<template>
  <div v-if="item" class="trainings-lesson-item--img mt-4">
    <v-img v-if="trainingData" :src="trainingData.coverImage"></v-img>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import {
  SET_QUIZ_SCORE,
  TRAINING_MODULE,
} from '@/stores/umanize-app/actions/training/training.actions';
import {
  COMPLETE_LESSION_TRAINING_SECTION,
  TRAINING_SECTION_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';

export default {
  name: 'TrainingsSectionItemDefault',
  components: {},
  props: {
    trainingData: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  async mounted() {
    if (!this.item.isDone) {
      const { sectionId, trainingId, id } = this.item;
      await this[COMPLETE_LESSION_TRAINING_SECTION](
        {
          eventId: this.$route.params.eventId,
          sectionId,
          trainingId,
          lessonId: id,
          isDone: true,
        },
        0,
      );
    }
  },
  methods: {
    ...mapActions(TRAINING_MODULE, [SET_QUIZ_SCORE]),
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [COMPLETE_LESSION_TRAINING_SECTION]),
  },
};
</script>

<style lang="scss">
@import 'src/styles/core/variables';

.trainings-lesson-item--quiz {
  border-radius: 5px;
  height: 100%;
  position: relative;
  padding: 0 0.5rem;

  .text__title {
    height: 50px;
    font-size: $xx-large-font-size;
    margin-bottom: 1rem;
    display: none;
  }

  .layout-4 {
    width: 80%;
    height: 80%;
    border-radius: 4px;
    background: white;
    padding: 1rem;
  }

  &__body {
    background: white;
    color: black;
    text-align: center;
    height: 550px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &__result {
      &__title {
        font-weight: bold;
        padding-top: 150px;
      }

      &__score {
        font-weight: bold;
        font-size: $xx-large-font-size;
      }

      &__edit-answer {
        cursor: pointer;
      }
    }

    &__question-number {
      padding-top: 45px;
      font-weight: bold;
    }

    &__content {
      margin-top: 50px;
    }

    &__answer {
      padding-top: 50px;
      width: 50%;
      margin: auto;

      &__content {
        display: flex;
        justify-content: center;

        button {
          margin-left: 1rem;
        }

        .v-btn:not(.v-btn--round).v-size--default {
          height: 48px !important;
          min-height: 48px !important;
        }
      }
    }

    &__process {
      width: 30%;
      margin: auto;
    }
  }

  &__footer {
    color: black;
    background: white;
    box-shadow: 0px -3px 6px #00000029;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: calc(100% - 1rem);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: 0;
  }

  @include breakpoint(medium) {
    padding: 0;
    .text__title {
      display: block;
    }
    &__body {
      &__question-number {
        padding-top: 100px;
      }

      &__process {
        padding-top: 20px;
      }
    }
    &__footer {
      width: 100%;
    }
  }
}
</style>
