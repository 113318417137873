<template>
  <div class="trainings-section">
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel v-for="(item, i) in trainingsSections" :key="i">
        <v-expansion-panel-header>
          <div class="trainings-section__body d-flex justify-space-between">
            <div class="trainings-section__body__image--left">
              <v-icon v-if="item.isDone" size="40" color="primary"> mdi-check</v-icon>
              <img
                v-else
                class="my-auto"
                src="../../../assets/icons/x2/Cercle_No-complet.png"
                width="25"
              />
            </div>
            <div class="trainings-section__body__content__left py-2 d-flex justify-space-between">
              <div class="trainings-section__body__content__left__wrap justify-space-between mx-4">
                <div
                  class="trainings-section__body__content__left__wrap--title mb-2 font-weight-bold"
                >
                  {{ item.name }}
                </div>
                <div class="trainings-section__body__content__left__wrap--duration">
                  {{ getProcess(item) }}
                </div>
              </div>
            </div>
            <div class="trainings-section__body__content__right d-flex justify-space-between">
              <div
                v-if="item.notes"
                class="trainings-section__body__content__right--notes yellow--text mr-1 d-flex align-center"
                :class="item.notes > 60 ? 'primary--text' : 'note-60'"
              >
                Notes: {{ item.notes }}%
              </div>
              <div
                v-if="false"
                class="trainings-section__body__content__right--notes grey--text mr-3 d-flex align-center"
              >
                {{ getNoteProcess(item) }}
              </div>
            </div>
            <div class="trainings-section__body__image--right">
              <v-img
                transition="fade-transition"
                lazy-src="../../../assets/icons/x1/Arrow_Bottom_Big_Section.png"
                class="my-auto expand-icon"
                :class="{ expanded: panel.includes(i) }"
                width="28"
                height="19"
                src="../../../assets/icons/x2/Arrow_Bottom_Big_Section.png"
              >
              </v-img>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expand-transition>
          <v-expansion-panel-content class="grey lighten-2">
            <div>
              <div v-for="(lesson, index) in item.lessons" :key="index">
                <TrainingsSectionItem
                  :item="lesson"
                  :section="item"
                  :isFinish="lesson.isDone"
                  :isLasted="index === item.lessons.length - 1"
                  :authorize="lesson.authorize"
                  @upload-doc="openUploadDocs"
                  @download-doc="openDownloadDocs"
                ></TrainingsSectionItem>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expand-transition>
      </v-expansion-panel>
    </v-expansion-panels>

    <UploadDocuments :dialog="modelUploadDocs" @close="closeUploadDocs" />
    <DownloadDocuments
      v-if="fileData"
      :dialog="modelDownloadDocs"
      :list-file="fileData"
      @close="closeDownloadDocs"
    />
  </div>
</template>

<script>
import { rightItemType } from '@/models/training/training-section-item.model';
import UploadDocuments from '@/components/trainings/upload-documents/UploadDocuments.vue';
import DownloadDocuments from '@/components/trainings/upload-documents/DownloadDocuments.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  GET_ALL_TRAININGS_LESSON_ADMIN,
  GET_FULL_TRAININGS_LESSON,
  TRAINING_LESSON_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import {
  SET_TRAINING_DATA,
  TRAINING_MODULE,
} from '@/stores/umanize-app/actions/training/training.actions';
import {
  GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON,
  TRAINING_SECTION_ADMIN_MODULE,
  GET_ALL_TRAININGS_SECTION_ADMIN,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';
import TrainingsSectionItem from './trainings-section-item/TrainingsSectionItem.vue';

export default {
  name: 'TrainingsSection',
  components: {
    TrainingsSectionItem,
    UploadDocuments,
    DownloadDocuments,
  },
  computed: {
    ...mapGetters(TRAINING_MODULE, ['trainingState']),
    ...mapGetters(TRAINING_LESSON_ADMIN_MODULE, ['allTrainingLessonData']),
    ...mapGetters(TRAINING_SECTION_ADMIN_MODULE, ['trainingSectionStateAreLoading']),
  },
  props: {
    trainingsSections: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [
      GET_ALL_TRAININGS_LESSON_ADMIN,
      GET_FULL_TRAININGS_LESSON,
    ]),
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON]),
    ...mapActions(TRAINING_MODULE, [SET_TRAINING_DATA]),
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [GET_ALL_TRAININGS_SECTION_ADMIN]),

    closeUploadDocs() {
      this.modelUploadDocs = false;
    },
    openUploadDocs() {
      this.modelUploadDocs = true;
    },
    closeDownloadDocs() {
      this.modelDownloadDocs = false;
      this.fileData = [];
    },
    openDownloadDocs(data) {
      switch (data.type) {
        case rightItemType.resources:
          this.fileData = data.content.files.accesFiles;
          break;
        case rightItemType.video:
          this.fileData = data.content.video.ressources;
          break;
        case rightItemType.reading:
          this.fileData = data.content.reading.ressources;
          break;
        default:
          this.fileData = [];
      }
      this.modelDownloadDocs = true;
    },
    getProcess(data) {
      if (!data?.lessons?.length) return '--/--';
      let finishLesson = 0;
      let duration = 0;
      data.lessons.forEach((ele) => {
        if (ele.isDone) finishLesson++;
        const { type } = ele;
        duration += ele?.content[type]?.approximateTime ?? 0;
      });
      return `${finishLesson}/${data.lessons.length} - ${duration} ${
        duration > 1 ? 'minutes' : 'minute'
      }`;
    },
    getNoteProcess(data) {
      if (!data?.lessons?.length) return '--/--';
      let finishLesson = 0;
      data.lessons.forEach((ele) => {
        if (ele.isDone) finishLesson++;
      });
      return `${finishLesson}/${data.lessons.length}`;
    },
  },
  data: () => ({
    modelUploadDocs: false,
    modelDownloadDocs: false,
    fileData: [],
    panel: [],
  }),
  watch: {
    trainingSectionStateAreLoading(loading) {
      if (!loading) {
        if (this.$route.params.lessonId) {
          this.trainingsSections.forEach((section, index) => {
            if (this.$route.params.sectionId === section.id) {
              this.panel.push(index);
            }
          });
        }
      }
    },
    $route: {
      handler() {
        const index = this.trainingsSections.findIndex(
          (section) => this.$route.params.sectionId === section.id,
        );
        if (index !== -1 && !this.panel.includes(index) && this.$route.params.lessonId) {
          this.panel.push(index);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import 'src/styles/core/variables';
.trainings-section::-webkit-scrollbar {
  display: none;
}
.trainings-section {
  // start - override class

  .v-item-group {
    .v-expansion-panel {
      margin-bottom: 0.5rem;
      border-radius: 5px;
      box-shadow: 3px 3px 10px #00000029;
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0 0.5rem !important;
  }

  .v-expansion-panel-header {
    padding: 0.75rem;
  }

  .v-expansion-panel-header__icon {
    display: none;
  }

  .theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent;
  }

  .v-tabs-items {
    background-color: transparent !important;
  }

  // end - override class
  &__body {
    position: relative;
    padding: 0.5rem 2rem;

    &__image {
      &--left {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
      }

      &--right {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
      }
    }

    &__content {
      &__left {
        &__wrap {
          &--title {
            font-size: $default-font-size;
          }

          &--duration {
            font-size: $default-font-size;
          }
        }
      }

      &__right {
        &--notes {
          width: 80px;
          justify-content: center;
        }
      }
    }
  }

  .note-60 {
    color: #d69700 !important;
  }

  .expand-icon {
    transition: 0.25s;
  }

  .expanded {
    transform: rotate(180deg);
  }

  @include breakpoint(medium) {
    height: 75vh;
    overflow-y: scroll;
    &__body {
      &__content {
        &__left {
          &__wrap {
            &--title {
              font-size: $default-font-size;
            }

            &--duration {
              font-size: $small-font-size;
            }
          }
        }
      }
    }
  }
}
</style>
