export enum trainingLessonTypeModel {
  VIDEO = 'video',
  BANNER = 'banner',
  READING = 'reading',
  EXTERNAL_LINK = 'websiteLink',
  QUIZ = 'quizz',
  DEPOSIT = 'deposit',
  FILE = 'file',
  PDF = 'pdf',
}
